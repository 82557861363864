<template>
  <div>
    <div class="search-con search-con-top">
      <div>
        <!-- 第一个选择器  选择学校  -->
        <Select
          v-model="signType"
          placeholder="选择订单号种类"
          @on-change="handleKindSignTypeChange"
          style="
            width: 200px;
            margin-top: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
          "
        >
          <Option
            v-for="item in signTypeList"
            :value="item.value"
            :key="item.value"
            >{{ item.value }}</Option
          >
        </Select>
        <!-- 第二个选择器  选择的科目 -->
        <Input
          v-if="signType === '按用户名搜索'"
          v-model="student"
          placeholder="请输入学生名称"
          @on-enter="handleSearchName"
          @on-clear="handleClearInput"
          clearable
          style="
            width: 250px;
            margin-top: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
          "
        />

        <Select
          v-if="privilege >= 3 && signType === '按顾问搜索'"
          v-model="advisor"
          style="
            width: 250px;
            margin-top: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
          "
        >
          <Option
            v-for="item in advisorList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>

        <Input
          v-if="
            signType === '所有订单' ||
            signType === '已付款' ||
            signType === '未付款'
          "
          v-model="transactionId"
          placeholder="请输入订单号"
          @on-enter="handleSearchTransaction"
          @on-clear="handleClearInput"
          clearable
          style="
            width: 250px;
            margin-top: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
          "
        />

        <DatePicker
          v-model="duration"
          :disabled="!ifDateRangeCheck"
          type="daterange"
          split-panels
          placement="top-end"
          placeholder="选择日期"
          style="margin-right: 10px; margin-bottom: 20px"
        />

        <Checkbox
          v-model="ifDateRangeCheck"
          @on-change="checkDateRange"
          style="margin-right: 20px"
          >选择日期(UTC)</Checkbox
        >

        <Button
          @click="handleSearch"
          class="search-btn"
          style="margin-top: 20px; margin-bottom: 20px"
          type="primary"
        >
          <Icon type="ios-search" />&nbsp;&nbsp;搜索
        </Button>
      </div>

      <Row :gutter="16">
        <Col
          style="margin-bottom: 20px"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="6"
          v-if="signType === '按顾问搜索'"
        >
          <Card>
            <p slot="title" v-if="advisorInfo.name === '' && privilege >= 3">
              所有顾问
            </p>
            <p slot="title" v-if="advisorInfo.name != '' && privilege >= 3">
              {{ advisorInfo.name }}
            </p>
            <p>时间段：{{ advisorInfo.duration }}</p>
            <p>成交单数：{{ advisorInfo.signed }}</p>
            <p>未付款单数：{{ advisorInfo.unpaid }}</p>
            <p>总成交额：{{ advisorInfo.total }}</p>
          </Card>
        </Col>

        <Col :md="24" :lg="signType === '按顾问搜索' ? 18 : 24">
          <Table
            :data="totalData"
            :columns="tableOrderColumns"
            :loading="loading"
            width="100%"
            stripe
            border
          >
            <!--         <template slot-scope="{ row }" slot="price">{{ row.price }}</template>-->
            <template slot-scope="{ row }" slot="action">
              <Modal v-model="paymentConfirmModal">
                <div>
                  <p>是否确认收到付款 ${{ dealPrice }}</p>
                </div>
                <div slot="footer">
                  <Button type="success" @click="ok()">确定</Button>
                  <Button type="error" @click="cancel">取消</Button>
                </div>
              </Modal>
              <Button
                ghost
                v-if="row.paid === false"
                type="warning"
                size="small"
                style="margin: 5px; width: 100px"
                @click="confirmOrderModal(row)"
                >确认付款</Button
              >
              <Button
                v-if="row.paid === false"
                type="error"
                size="small"
                style="margin-bottom: 5px; width: 100px"
                @click="removeOrderModal(row)"
                >删除订单</Button
              >
              <Button
                ghost
                v-if="row.paid"
                type="primary"
                size="small"
                style="margin: 5px; width: 100px"
                @click="viewOrderModal(row)"
                >查看详情</Button
              >
              <Button
                v-if="row.paid"
                width="100px"
                type="error"
                size="small"
                style="margin-bottom: 5px; width: 100px"
                @click="removeOrderModal(row)"
                >退课</Button
              >
            </template>
          </Table>
        </Col>
      </Row>

      <div style="margin: 10px; overflow: hidden">
        <div style="display: flex; justify-content: flex-end">
          <Page
            :total="totalOrderCount"
            :current="currentPage"
            @on-change="changePage"
            :pageSize="pageSize"
          ></Page>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
// import { Input } from 'view-design';
export default {
  inject: ["refresh"],
  name: "manageOrder",
  data: function () {
    return {
      advisorList: [],
      confirmModal1: false,
      paymentConfirmModal: false,
      loading: true,
      rowinfo: null,
      noteinfo: "",
      dealPrice: 0.0,
      signType: "所有订单",
      student: "",
      advisor: "所有顾问",
      signTypeList: [
        { value: "所有订单" },
        { value: "已付款" },
        { value: "未付款" },
        { value: "按用户名搜索" },
        { value: "按顾问搜索" }
      ],
      tableStudentData: [],
      tableOrderColumns: [
        {
          title: "状态",
          key: "paid",
          render: (h, { row }) => {
            return (
              <div>
                <Tag color={row.paid ? "success" : "warning"}>
                  <strong> {row.paid ? "已付款" : "未付款"} </strong>
                </Tag>
              </div>
            );
          },
          width: 100,
          align: "center"
        },
        {
          title: "订单号",
          key: "transaction_id",
          minWidth: 135
        },
        {
          title: "学生",
          key: "student",
          render: (h, { row }) => {
            const user = row.student;
            const name = row.student_name;
            return h("div", [
              h("p", name),
              h("p", { style: { color: "#ccc" } }, user)
            ]);
          },
          minWidth: 100
        },
        {
          title: "报课顾问",
          key: "advisor",
          render: (h, { row }) => {
            const user = row.advisor;
            const name = row.advisor_name;
            if (user == null) return h("p", "-");
            return h("div", [
              h("p", name),
              h("p", { style: { color: "#ccc" } }, user)
            ]);
          },
          minWidth: 100
        },
        {
          title: "课程编号",
          key: "course_code",
          render: (h, { row }) => {
            const fullCode = row.course_code;
            let unitCode = "-";
            let courseType = "-";
            const matches = fullCode.match(/[a-zA-Z]+\d+/g);
            if (matches !== null) {
              unitCode = matches[0];
              courseType = fullCode.slice(unitCode.length);
            }
            return h("div", [
              h("p", unitCode),
              h("p", { style: { color: "#ccc" } }, courseType)
            ]);
          },
          minWidth: 120
        },
        {
          title: "报名时间",
          key: "sign_time",
          render: (h, { row }) => {
            const signTime = moment(row.sign_time).format("YYYY-MM-DD HH:mm");
            let paidTime = moment(row.paid_time).format("YYYY-MM-DD HH:mm");
            if (!row.paid) paidTime = "-";

            // console.log(row.advisor);
            return h("div", [
              h("p", signTime),
              h("p", { style: { color: "#ccc" } }, paidTime)
            ]);
          },
          width: 150
        },
        {
          slot: "price",
          title: "价格",
          key: "price",
          align: "center",
          width: 100,
          // slot: "price",
          render: (h, { row }) => {
            // console.log(row);
            const price =
              row.deal_price == null ? row.original_price : row.deal_price;
            if (row.paid) {
              // <Tag color="success">已收款:
              //       ${parseFloat(price).toFixed(2)}
              // </Tag>
              if (row.deal_price == row.original_price)
                return (
                  <span style="color: #19be6b; fontSize: 14px;">
                    <strong>${parseFloat(price).toFixed(2)} </strong>
                  </span>
                );
              else
                return (
                  <span style="color: #19be6b; fontSize: 14px;">
                    <strong>${parseFloat(price).toFixed(2)} </strong>
                    <br />
                    <del style="color: red">
                      ${parseFloat(row.original_price).toFixed(2)}{" "}
                    </del>
                  </span>
                );
            } else {
              return (
                <span style="color: #f90; fontSize: 14px;">
                  <strong>${parseFloat(price).toFixed(2)}</strong>
                </span>
              );
            }
          }
        },
        {
          title: "Action",
          slot: "action",
          width: 150,
          align: "center"
        }
      ],
      currentPage: 1,
      pageSize: 10,
      totalOrderCount: 0,
      totalData: [],
      transactionId: "",
      note: "",
      ifDateRangeCheck: true,
      duration: "",
      advisorInfo: {
        name: "",
        duration: "",
        signed: 0,
        unpaid: 0,
        total: 0
      }
    };
  },
  methods: {
    checkDateRange() {
      this.duration = "";
    },
    makeParam() {
      const param = { page: this.currentPage };
      if (this.transactionId && this.transactionId !== "") {
        param.tid = this.transactionId;
      }

      if (this.ifDateRangeCheck && this.duration[0]) {
        const start_time = moment(this.duration[0]).format("YYYY-MM-DD");
        const end_time = moment(this.duration[1]).format("YYYY-MM-DD");

        param.start_time = start_time;
        param.end_time = end_time;

        if (start_time === end_time) {
          const new_end_time = new Date(end_time);
          new_end_time.setDate(new_end_time.getDate() + 1);
          param.end_time = moment(new_end_time).format("YYYY-MM-DD");
        }
      }
      return param;
    },
    handleKindSignTypeChange(selectedType) {
      this.loading = true;
      this.transactionId = "";
      if (selectedType === "已付款") {
        const param = this.makeParam();
        param["paid"] = 1;
        this.axios
          .get("api/advisor/courses/signed", { params: param })
          .then(resp => {
            const respData = resp.data;
            this.setData(respData);
          });
      } else if (selectedType === "未付款") {
        const param = this.makeParam();
        param["paid"] = 0;
        this.axios
          .get("api/advisor/courses/signed", { params: param })
          .then(resp => {
            const respData = resp.data;
            this.setData(respData);
          });
      } else {
        const param = this.makeParam();
        this.axios
          .get("api/advisor/courses/signed", { params: param })
          .then(resp => {
            const respData = resp.data;
            this.setData(respData);
          });
      }
    },
    changePage(currentPage) {
      this.currentPage = currentPage;
      this.reload();
    },
    handleSearch() {
      this.currentPage = 1;
      this.reload();
    },
    reload() {
      this.loading = true;
      const param = this.makeParam();
      if (this.signType === "按顾问搜索") {
        if (this.advisor !== "" && this.advisor !== "所有顾问") {
          param.advisor = this.advisor;
        }
        this.axios
          .get("api/advisor/courses/signed", { params: param })
          .then(resp => {
            const respData = resp.data;
            this.advisorInfo.name = this.advisor;
            if (this.ifDateRangeCheck && this.duration[0]) {
              this.advisorInfo.duration =
                moment(this.duration[0]).format("YYYY-MM-DD") +
                "至" +
                moment(this.duration[1]).format("YYYY-MM-DD");
            } else {
              this.advisorInfo.duration = "所有";
            }
            let paid = 0;
            let unpaid = 0;
            let total = 0;
            for (const order of respData.items) {
              if (order.paid) {
                paid += 1;
                total += order.deal_price;
              } else {
                unpaid += 1;
              }
            }
            this.advisorInfo.signed = paid;
            this.advisorInfo.unpaid = unpaid;
            this.advisorInfo.total = total;
            this.setData(respData);
          });
      } else if (this.signType === "按用户名搜索") {
        if (this.student && this.student.trim().length !== 0) {
          param.student_name = this.student;
        }
        this.axios
          .get("api/advisor/courses/signed", { params: param })
          .then(resp => {
            const respData = resp.data;
            this.setData(respData);
          })
          .catch(err => {
            this.loading = false;
            this.$Message.error("没有找到该学生的订单");
          });
          ;
      } else if (this.signType === "所有订单") {
        this.axios
          .get("api/advisor/courses/signed", { params: param })
          .then(resp => {
            const respData = resp.data;
            this.setData(respData);
          });
      } else if (this.signType === "已付款") {
        param["paid"] = 1;
        this.axios
          .get("api/advisor/courses/signed", { params: param })
          .then(resp => {
            const respData = resp.data;
            this.setData(respData);
          });
      } else if (this.signType === "未付款") {
        param["paid"] = 0;
        this.axios
          .get("api/advisor/courses/signed", { params: param })
          .then(resp => {
            const respData = resp.data;
            this.setData(respData);
          });
      }
    },
    handleClearInput() {
      this.setData(this.totalData);
    },
    confirmPayment(row) {
      this.rowinfo = row;
      this.noteinfo = row.advisor_note;
      this.$Modal.remove();
      this.paymentConfirmModal = true;
    },
    confirmOrderModal(row) {
      this.dealPrice = row.deal_price;
      this.$Modal.confirm({
        render: () => {
          return (
            <div style="display: flex; flex-direction:column; margin-top: 20px;">
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">订单号:</span> &nbsp;&nbsp;
                {row.transaction_id}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">学生用户:</span> &nbsp;&nbsp;
                {row.student}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">报名科目:</span> &nbsp;&nbsp;
                {row.course_code}
              </div>

              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">学校名称:</span> &nbsp;&nbsp;
                {row.campus}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">学院名称:</span> &nbsp;&nbsp;
                {row.faculty}
              </div>
              <Divider></Divider>
              <div style="font-size: 16px;margin: 6px 0; display: flex;">
                <label style="font-weight: bold; ">
                  实收金额:&nbsp;&nbsp;
                  <Input
                    placeholder={this.dealPrice}
                    number="ture"
                    prefix="logo-usd"
                    v-model={this.dealPrice}
                    style="width: 250px;"
                  />
                </label>
              </div>
              <div style="font-size: 16px;margin: 6px 0; display: flex;">
                <div style="font-weight: bold; ">报名备注:</div> &nbsp;&nbsp;
                <Input
                  type="textarea"
                  maxlength="200"
                  show-word-limit
                  placeholder={row.advisor_note}
                  v-model={row.advisor_note}
                  id={row.transaction_id}
                  style="width: 250px;"
                />
              </div>
            </div>
          );
        },
        title: `确认收款`,
        loading: true,
        onCancel: () => {
          this.$Modal.remove();
        },
        cancelText: "取消",
        onOk: () => {
          this.rowinfo = row;
          this.noteinfo = row.advisor_note;
          this.$Modal.remove();
          this.paymentConfirmModal = true;
        }
      });
    },
    removeOrderModal(row) {
      // console.log(row.transaction_id);
      this.$Modal.confirm({
        title: `<h3>订单号${row.transaction_id}</h3>`,
        content: `<div>确认为${row.student}删除${row.course_code}课程?</div>`,
        onCancel: () => {
          this.$Modal.remove();
        },
        onOk: async () => {
          const { student, course_code } = row;
          try {
            const resp = await this.axios.delete(
              `api/student/${student}/course/${course_code}`
            );
            if (resp.status === 200) {
              this.$Message.success(`您已经成功删除了${row.course_code}服务!`);
              this.refresh();
            }
            this.axios.get("api/courses/signed").then(resp => {
              // console.log(resp.data)
              this.orderData = resp.data;
            });
          } catch {
            this.$Message.error("删除服务失败");
          } finally {
            this.$Modal.remove();
          }
        }
      });
      return row.transaction_id;
    },
    cancel() {
      this.paymentConfirmModal = false;
    },
    async ok() {
      const { transaction_id } = this.rowinfo;
      const note = this.noteinfo;
      const deal_price = this.dealPrice * 1;
      const advisorNote = {
        advisor_note: note,
        deal_price: deal_price
      };
      try {
        const resp = await this.axios.post(
          `api/advisor/courses/signed/${transaction_id}/paid`,
          advisorNote
        );
        this.$Message.success(
          `已成功确认订单号为${resp.data.transaction_id}的付款。`
        );
      } catch {
        this.$Message.error("确认付款失败");
      } finally {
        this.paymentConfirmModal = false;
        this.handleKindSignTypeChange("所有订单");
      }
    },
    viewOrderModal(row) {
      // console.log(row.transaction_id);
      this.$Modal.confirm({
        render: () => {
          return (
            <div style="display: flex; flex-direction:column; margin-top: 20px;">
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">学生用户:</span> &nbsp;&nbsp;
                {row.student}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">报名科目:</span> &nbsp;&nbsp;
                {row.course_code}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">学校名称:</span> &nbsp;&nbsp;
                {row.campus}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">学院名称:</span> &nbsp;&nbsp;
                {row.faculty}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">课程原价:</span> &nbsp;&nbsp;
                ${row.original_price}
              </div>
              <div style="font-size: 16px;margin: 6px 0;">
                <span style="font-weight: bold; ">实收金额:</span> &nbsp;&nbsp;
                ${row.deal_price}
              </div>
              <div style="font-size: 16px;margin: 6px 0; display: flex;">
                <span style="font-weight: bold; ">报名备注:</span> &nbsp;&nbsp;
                {row.advisor_note}
              </div>
              <div style="font-size: 16px;margin: 6px 0; display: flex;">
                <span style="font-weight: bold; ">修改备注:</span> &nbsp;&nbsp;
                <Input
                  type="textarea"
                  maxlength="200"
                  show-word-limit
                  placeholder={row.advisor_note}
                  v-model={row.advisor_note}
                  id={row.transaction_id}
                  style="width: 250px;"
                />
              </div>
            </div>
          );
        },
        onOk: async () => {
          const { transaction_id } = row;
          const note = row.advisor_note;
          const advisorNote = { advisor_note: note };
          try {
            if (note.length != 0) {
              const resp = await this.axios.put(
                `api/advisor/courses/signed/${transaction_id}/paid`,
                advisorNote
              );
              this.$Message.success(`已成功修改${resp.data.student}的备注。`);
            }
          } catch {
            this.$Message.error("修改备注失败");
          } finally {
            // this.$Modal.remove();
            this.handleKindSignTypeChange("所有订单");
          }
        }
      });
    },
    quitCourseModal(row) {
      return row.transaction_id;
    },
    setData(rawRespData) {
      const respData = rawRespData;
      this.totalOrderCount = respData.total_items;
      this.totalData = respData.items;
      this.loading = false;
    }
  },
  computed: {
    privilege: function () {
      return this.$store.getters.getCurrentUser.privilege;
    }
  },
  mounted: function () {
    //默认日期 从今天开始后推一个月：
    const today = new Date();
    const toDate = new Date().setDate(today.getDate() + 1);
    const fromDate = new Date().setDate(today.getDate() - 3);

    //sign给duration：
    this.duration = [
      moment(fromDate).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD")
    ];

    //返回所有一个月记录
    this.axios
      .get("api/advisor/courses/signed", {
        params: {
          page: this.currentPage,
          start_time: this.duration[0],
          end_time: this.duration[1]
        }
      })
      .then(resp => {
        //console.log(resp);
        const respData = resp.data;
        this.setData(respData);
        this.loading = false;

        //计算该月所有顾问所有订单信息,作为default
        this.advisorInfo.duration =
          moment(this.duration[0]).format("YYYY-MM-DD") +
          "至" +
          moment(this.duration[1]).format("YYYY-MM-DD");
        let paid = 0;
        let unpaid = 0;
        let total = 0;
        for (const order of respData.items) {
          if (order.paid) {
            paid += 1;
            total += order.deal_price;
          } else {
            unpaid += 1;
          }
        }
        this.advisorInfo.signed = paid;
        this.advisorInfo.unpaid = unpaid;
        this.advisorInfo.total = total;
      });

    //返回所有顾问名字：
    this.axios
      .get("api/users", {
        params: {
          userType: "advisor"
        }
      })
      .then(resp => {
        this.advisorList.push({
          value: "所有顾问",
          label: "--所有顾问--"
        });
        for (const user of resp.data) {
          const newAdvisor = {
            value: user.username,
            label: user.display_name
          };
          this.advisorList.push(newAdvisor);
        }
      });
  }
};
</script>
